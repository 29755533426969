import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import SiteDataForm from '../components/forms/siteDataForm';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class SiteDataPage extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);

        this.state = {
        };
    }


    componentDidMount() {
        if (!localStorage.token){
            return;
        }

        fetch('https://devlery-api.novamedia.agency/admin/data', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                initialValues: result
            })
        })
    }

    save(data) {
        if (!localStorage.token){
            return;
        }

        fetch('https://devlery-api.novamedia.agency/admin/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((result) => {
            this.props[0].history.push('/');
        })

    }


    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>Site data</h3>
                        </Col>

                    </Row>
                    {this.state.initialValues ?
                        <SiteDataForm initialValues={this.state.initialValues} onSubmit={this.save} />
                        :
                        <SiteDataForm onSubmit={this.save} />

                    }
                </Container>


            </div>
        );
    }
}

export default Page(SiteDataPage);