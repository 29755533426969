import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import HtmlImage from './fields/htmlImage';
import Html from './fields/html';
import Select from './fields/select';
import Section from './fields/section';

import image from '../../assets/svg/image.svg';
import deleteIcon from '../../assets/svg/delete.svg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



const renderHtmlImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <HtmlImage
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )

const renderSectionField = ({
    input,
    meta: { touched, error },
}) => (

        <Section
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )




class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            parentElements: []
        }
    }

    componentDidMount() {

        fetch('https://devlery-api.novamedia.agency/admin/fetch/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                parentElements: result
            })
        })

    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(this.props);

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12" >
                        <Container fluid className="form-box">
                            <Row>
                                <Col lg="12">
                                    <h3 className="title">Template name</h3>
                                    <h6 className="subtitle">Enter name of template</h6>

                                </Col>
                                <Col lg="6" className="input-wrap">
                                    <Field
                                        name="name"
                                        component={renderTextField}
                                        label={"Template name"}
                                        placeholder="Enter name of template"

                                    ></Field>

                                </Col>
                                <Col lg="3" className="input-wrap">
                                    <Field
                                        name="category"
                                        component={renderSelectField}
                                        label={"Category"}
                                        placeholder="Choose category"
                                    >
                                    {
                                        this.state.parentElements.map((item, idx) => {
                                            return (
                                                <option value={item.name}>{item.name}</option>
                                            )
                                        })
                                    }
                                    </Field>

                                </Col>


                            </Row>
                        </Container>
                    </Col>
                    <Field
                        name="pages"
                        component={renderSectionField}
                        
                    >
                    </Field>




                    <Col lg="12">
                        <button className="button">Save</button>

                    </Col>

                </Row>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
