import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>

                </div>

                <div className="items">
                <h6>TEMPLATES</h6>
                    <ul>

                        <li>
                            <Link to='/collection/pages' className={this.props[0].location.pathname == '/collection/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                All templates
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/pages/new' className={this.props[0].location.pathname == '/collection/pages/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New tamplate
                            </Link>
                        </li>




                    </ul>
                  
                    <h6>CATEGORIES</h6>
                    <ul>

                        <li>
                            <Link to='/collection/categories' className={this.props[0].location.pathname == '/collection/categories' ? 'active' : null}>
                                <Isvg src={list} />
                                All categories
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/categories/new' className={this.props[0].location.pathname == '/collection/categories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New category
                            </Link>
                        </li>


                    </ul>

                    <h6>REVIEWS</h6>
                    <ul>

                        <li>
                            <Link to='/collection/reviews' className={this.props[0].location.pathname == '/collection/reviews' ? 'active' : null}>
                                <Isvg src={list} />
                                All reviews
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/reviews/new' className={this.props[0].location.pathname == '/collection/reviews/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New review
                            </Link>
                        </li>


                    </ul>

                    <h6>REQUESTS</h6>
                    <ul>
                        <li>
                            <Link to='/requests' className={this.props[0].location.pathname == '/requests' ? 'active' : null}>
                                <Isvg src={mail} />
                                All requests
                            </Link>
                        </li>

                    </ul>



                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Log Out
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div>
        )
    }

};

export default Sidebar;