import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import Textarea from './fields/textarea';
import HtmlImage from './fields/htmlImage';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

    const renderTextareaField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
    }) => (
    
            <Textarea
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                {...input}
            />
        )
    
    
    

const renderHtmlImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <HtmlImage
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Name of site and contact</h3>
                                <h6 className="subtitle">Enter the necessary information to better index your site in search engines</h6>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="tags"
                                    component={renderTextField}
                                    label={"Meta tags"}
                                    placeholder="Enter as many keywords as you can about your site (comma separated)"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="description"
                                    component={renderTextField}
                                    label={"Site description"}
                                    placeholder="Enter a description for your site"

                                ></Field>

                            </Col>

                        </Row>
                    </Container>
                </Col>


                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
