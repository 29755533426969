import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import PageForm from '../components/forms/pageForm';
import SlideForm from '../components/forms/slideForm';
import CategoryForm from '../components/forms/categoryForm';
import ReviewForm from '../components/forms/reviewForm';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class AddPagePage extends Component {
    constructor(props) {
        super(props);
        this.addPage = this.addPage.bind(this);

        this.state = {

        };
    }

    addPage(data) {
        console.log(data);

        fetch('https://devlery-api.novamedia.agency/admin/updateOne/' + this.props[0].match.params.collection + '/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((res) => {
            console.log("redirect")
            this.props[0].history.push('/collection/' + this.props[0].match.params.collection)
        });
    }

    componentDidMount() {
        if (this.props[0].match.params.id != 'new') {
            fetch('https://devlery-api.novamedia.agency/admin/fetchOne/' + this.props[0].match.params.collection + '/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    initialValues: result
                })
                console.log(result);
            })

        }
    }


    render() {
        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    {/*<Row className="page-title">
                        <Col lg="12">
                            {this.props[0].match.params.id !== 'new' ? <h3>Izmjeni stranicu</h3> : <h3>Dodaj stranicu</h3>}
                        </Col>

                    </Row>
                    */
            }
                    {this.state.initialValues ?
                        this.props[0].match.params.collection != 'reviews' ?
                            this.props[0].match.params.collection == 'categories' ?
                                <CategoryForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />

                                :
                                <PageForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />
                            :
                            <ReviewForm collection={this.props[0].match.params.collection} initialValues={this.state.initialValues} onSubmit={this.addPage} />


                        :

                        this.props[0].match.params.collection != 'reviews' ?
                            this.props[0].match.params.collection == 'categories' ?
                                <CategoryForm collection={this.props[0].match.params.collection} onSubmit={this.addPage} />

                                :

                                <PageForm collection={this.props[0].match.params.collection} onSubmit={this.addPage} />
                            :
                            <ReviewForm collection={this.props[0].match.params.collection} onSubmit={this.addPage} />

                    }
                </Container>


            </div>
        );
    }
}

export default Page(AddPagePage);