
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Image from './image';
import Text from './text';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import deleteIcon from '../../../assets/svg/delete.svg';
import camera from '../../../assets/svg/camera.svg';

class Section extends Component {
    constructor(props) {
        super(props);

        this.state = {
            urls: [],
            loading: []
        };
    }






    render() {
        console.log(this.props.value)
        //console.log(generateAlias("ćčććasd"))
        return (
            <>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Pages</h3>
                                <h6 className="subtitle">Add new page</h6>

                            </Col>
                            <Col lg="2">
                                <button type="button" onClick={() => {
                                    let value = this.props.value;
                                    if (!value) {
                                        value = [];
                                    }
                                    value.push(null);
                                    this.props.onChange(value);
                                    this.forceUpdate();
                                }} className="button add-section-btn">New page</button>

                            </Col>
                        </Row>
                    </Container>
                </Col>
                {
                    this.props.value && this.props.value.map((item, idx) => {
                        return (
                            <Col lg={"3"} xs={"4"} >
                                <Container fluid className="form-box">
                                    <Row>
                                        <Col lg="10">
                                            <h3 className="title">Page picture</h3>
                                            <h6 className="subtitle">Uplaod picture of page template</h6>

                                        </Col>
                                        <Col lg="2">
                                            <button type="button" className="delete-btn" onClick={() => {
                                                let value = this.props.value;
                                                value.splice(idx, 1);
                                                this.props.onChange(value);
                                                this.forceUpdate();

                                            }}> <Isvg src={deleteIcon} /> </button>
                                        </Col>
                                        <Col lg="9" className="input-wrap input-wrap-no-p">
                                            <Text placeholder="Enter url of template" value={this.state.urls[idx]} onChange={(e) => {
                                                let urls = this.state.urls;
                                                urls[idx] = e.target.value;
                                                this.setState({
                                                    urls
                                                })
                                            }} />
                                        </Col>
                                        <Col lg="3">
                                            <button type="button" className="button camera-btn" onClick={() => {
                                                            let loading = this.state.loading;
                                                            loading[idx] = true;
                                                               this.setState({
                                                                   loading
                                                               }, () => {
                                                                fetch('https://devlery-api.novamedia.agency/admin/screenshot', {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        Accept: 'application/json',
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                
                                                                    },
                                                                    body: JSON.stringify({url: this.state.urls[idx]})
                                                                }).then((res) => res.text()).then((img) => {
                                                                    let value = this.props.value;
                                                                    value[idx] = img;
                                                                    this.props.onChange(value);
                                                                    let loading = this.state.loading;
                                                                    loading[idx] = null;
                                                                    this.setState({
                                                                        loading
                                                                    })
                                                                    this.forceUpdate();
                                                                    
                    
                                                                    //this.props.onChange(img);
                                                                });
                                                               })
                                                               
                                             
                                            }}>
                                            {
                                                this.state.loading[idx] ?
                                                <div className="lds-dual-ring"></div>
                                                    :
                                            <Isvg src={camera} />
                                            }
                                            </button>
                                        </Col>

                                        <Image
                                            value={item}
                                            onChange={(val) => {
                                                console.log(val);
                                                let value = this.props.value;
                                                value[idx] = val;
                                                this.props.onChange(value);
                                                this.forceUpdate();
                                            }}
                                        ></Image>



                                    </Row>
                                </Container>
                            </Col>

                        )
                    })
                }

            </>


        );
    }
}

export default Section;